<template>
  <div class="container">
    <form class="sign-in-form" @submit.prevent="resetPassword">
      <p class="text-center">
        <img src="/assets/img/logo.svg" width="200" />
      </p>
      <div class="card">
        <div class="card-body block-el">
          <template v-if="loaded">
            <template v-if="form.token">
              <h5 class="sign-in-heading text-center">{{$t('reset-password.label-2')}}</h5>
              <p class="text-center text-muted">{{$t('reset-password.type-password')}}</p>
              <password-input v-model="form.password" :disabled="isSending" />
              <button
                class="btn btn-yup-purple btn-block"
                :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}"
                :disabled="isSending"
              >{{$t('reset-password.reset')}}</button>
            </template>
            <div
              v-else
              class="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <strong>{{$t('reset-password.invalid-token')}}</strong> {{$t('reset-password.invalid-token-label')}}
              <router-link
                to="/reset-password"
                class="alert-link"
              >{{$t('reset-password.reset-2')}}</router-link>.
            </div>
            <p class="text-muted m-t-25 m-b-0 p-0 text-center">
              <router-link to="/login">{{$t('reset-password.to-login')}}</router-link>
            </p>
          </template>
          <div v-else class="qt-block-ui"></div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import PasswordInput from '@/components/form/PasswordInput.vue';

export default {
  name: 'ResetVerification',
  components: {
    PasswordInput,
  },
  data() {
    return {
      loaded: false,
      form: {
        password: '',
        token: '',
      },
      isSending: false,
    };
  },
  created() {
    this.fetchToken();
  },
  methods: {
    fetchToken() {
      this.$store
        .dispatch('auth/resetVerification', this.$route.params.token)
        .then((response) => {
          this.form.token = response.token;
        })
        .finally(() => {
          this.loaded = true;
        });
    },
    resetPassword() {
      this.isSending = true;
      this.$store
        .dispatch('auth/resetPassword', this.form)
        .then(() => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('reset-password.successs'),
            type: 'success',
          });
          this.$router.push('/login');
        })
        .finally(() => {
          this.isSending = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.block-el {
  min-height: 170px;
}
</style>
